<template>
  <div>
    <div class="px-3">
      <label for="" class="editorStyleTitle">Input Type:</label>
      <div class="d-flex gap editor-input-control">
    <span v-html=" freeSvgs[block.selectedInfoType.text]"></span>
    <span>{{block.selectedInfoType.text}}</span>
  </div>
    </div>
    <hr>
    <!-- block.style.DateFontFamily -->
    <div class="px-3">
      <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Show Label:</span>
        <span class="d-flex align-items-center gap">{{!block.showLabel?'Show':'Hide'}}  <b-form-checkbox
                       :id="`ShowLabel-${Date.now()}`"
                v-model="block.showLabel"
                  ></b-form-checkbox></span>
      </label>
      <input type="text" v-if="block.showLabel" placeholder="Label text" v-model="block.labelText" class="w-100 editor-input-control" id="">
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Required field:</span>
        <b-form-checkbox
                       :id="`isRequired-${Date.now()}`"
                v-model="block.isRequired"
                  ></b-form-checkbox>
      </label>
      <label for="" v-if="block.selectedInfoType.fieldName == 'date'" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Use Date Picker:</span>
        <b-form-checkbox
                       :id="`DatePicker-${Date.now()}`"
                v-model="block.isDatePicker"
                  ></b-form-checkbox>
      </label>
      <label for="" class="editorStyleTitle mt-2 d-flex align-items-center justify-content-between">
        <span>Sample Text:</span>
       
      </label>
      <input type="text"  placeholder="Sample Text" v-model="block.InfoTypePlaceholder" class="w-100 editor-input-control" id="">
    </div>
    
    <hr>
    <div v-if="block.selectedInfoType.fieldName == 'date'" class="">
 <div class="px-3">
  <div class="d-flex align-items-center gap">
    <label for="" class="m-0 editorStyleTitle" style="white-space:nowrap;">Date format:</label>
     
    <select style="background-color: #F7F7F7;"
                v-model="block.selectedDateFormat" 
                class="editor-input-control w-100 m-0 position-relative">
                  <option value="" disabled>Select date format</option>
                  <option  v-for="(format, ind) in block.dateFormat"
                  :key="ind" :value="format.value">
                  {{ format.value }}
                  </option>
              </select>
  </div>
  <div class="d-flex align-items-center gap mt-2">
    <label for="" class="m-0 editorStyleTitle" style="white-space:nowrap;">Date Separator:</label>
     
    <select style="background-color: #F7F7F7;"
                v-model="block.selectedDateFormatSeperator" 
                class="editor-input-control w-100 m-0 position-relative">
                  <option value="" disabled>Date Separator</option>
                  <option  v-for="(seperator, ind) in block.dateFormatSeperator"
                  :key="ind" :value="seperator.value">
                  {{ seperator.value }}
                  </option>
              </select>
  </div>
  <div class="">
    <label for="" class="editorStyleTitle" style="white-space:nowrap;">Text:</label>
    <div class="d-flex" style="gap:4px;">
      <input type="text" placeholder="Date" v-model="block.selectedInfoType.dateText" class="w-100 editor-input-control" id="">

      <input type="text"  placeholder="Month" v-model="block.selectedInfoType.monthText" class="w-100 editor-input-control" id="">

      <input type="text"  placeholder="Year" v-model="block.selectedInfoType.yearText" class="w-100 editor-input-control" id="">

    </div>
  </div>
 </div>
 <hr>
 <div class="px-3">
  <FontFamily  v-model="block.style.DateFontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
  <input type="number" v-model="block.style.DateFontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
</div>
<FontStyles :textBold.sync="block.style.DateFontWeight" 
  :textItalic.sync="block.style.DateFontStyle"
  :textUnderLine.sync="block.style.DateTextDecoration" class=""></FontStyles>
   
  </div>

 </div>
 <hr>
</div>
    <div class="px-3">
  <SelectColor v-model="block.style.color"  :opacity.sync="block.style.textInputOpacity"  :label="'Text Color:'"></SelectColor>
  <TextAlign class="mt-2" v-model=block.style.textInputAlign ></TextAlign>
</div>
<hr>
<div class="px-3">
  <label for=""   class="editor-label">Stroke:</label>
  <SelectColor v-model="block.style.strokeColor"  :opacity.sync="block.style.strokeOpacity"  :label="'Text Color:'"></SelectColor>
  <div class="d-flex justify-content-center mt-2" style="gap:4px">
    <div class="d-flex text-align-section w-100" style="gap:4px;max-width: -webkit-fit-content;max-width: fit-content;">
      <label for="" :class="{
                'label-active': block.style.borderType === 'border',
              }"
              @click="block.style.borderType = 'border'"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.8333 2.5H4.16667C3.24167 2.5 2.5 3.24167 2.5 4.16667V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V4.16667C17.5 3.24167 16.75 2.5 15.8333 2.5ZM15.8333 4.16667V15.8333H4.16667V4.16667H15.8333Z" fill="currentColor"/>
</svg>
</label>
<label for=""    :class="{
                'label-active': block.style.borderType === 'border-bottom',
              }"
              @click="block.style.borderType = 'border-bottom'" ><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5455 8.72727H13.091V7.27273H14.5455V8.72727ZM2.90914 8.72727H1.45459V7.27273H2.90914V8.72727ZM8.72732 2.90909H7.27277V1.45454H8.72732V2.90909ZM11.6364 2.90909H10.1819V1.45454H11.6364V2.90909ZM14.5455 5.81818H13.091V4.36364H14.5455V5.81818ZM14.5455 2.90909H13.091V1.45454H14.5455V2.90909ZM14.5455 11.6364H13.091V10.1818H14.5455V11.6364ZM2.90914 11.6364H1.45459V10.1818H2.90914V11.6364ZM5.81823 2.90909H4.36368V1.45454H5.81823V2.90909ZM2.90914 2.90909H1.45459V1.45454H2.90914V2.90909ZM2.90914 5.81818H1.45459V4.36364H2.90914V5.81818ZM14.5455 14.5455H1.45459V13.0909H14.5455V14.5455Z" fill="currentColor"/>
</svg>
</label>
    </div>
  <Stroke :borderSize.sync="block.style.borderSize"
  :borderRadius.sync="block.style.borderRadius"  ></Stroke>
</div>
 </div>
 <hr>
 
 <div class="px-3 mb-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.marginRight" :left.sync="block.style.marginLeft" :top.sync="block.style.marginTop" :bottom.sync="block.style.marginBottom"></padding>
  <padding  v-else :label="'Spacing (Mobile)'"  :right.sync="block.style.mobileMarginRight" :left.sync="block.style.mobileMarginLeft" :top.sync="block.style.mobileMarginTop" :bottom.sync="block.style.mobileMarginBottom"></padding> 
</div>
  </div>
</template>

<script>

import fontFamilyList from "../../../staticPages/fontFamilyList.js";
import TextAlign from "../MiniComponents/TextAlign.vue";
import SelectColor from '../MiniComponents/SelectColor.vue';
import Stroke from "../MiniComponents/Stroke.vue";
import FontFamily from "../MiniComponents/FontFamily.vue";
import FontStyles from "../MiniComponents/FontStyles.vue";
import Padding from "../MiniComponents/Padding.vue";

export default {
  props: {
    block: Object,
    index: Number,
    isDesktopView: Boolean,
    QuestionPageType: String,
    getAllUsedLeadInputs: Array,
  },
components:{
  TextAlign,
  SelectColor,
  Stroke,
  FontStyles,
  FontFamily,
  Padding
},
  data() {
    return {
      quizID: null,
      freeSvgs:{
        "Full Name":`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 14.1667C5 12.5 8.33333 11.5833 10 11.5833C11.6667 11.5833 15 12.5 15 14.1667V15H5M12.5 7.5C12.5 8.16304 12.2366 8.79893 11.7678 9.26777C11.2989 9.73661 10.663 10 10 10C9.33696 10 8.70107 9.73661 8.23223 9.26777C7.76339 8.79893 7.5 8.16304 7.5 7.5C7.5 6.83696 7.76339 6.20107 8.23223 5.73223C8.70107 5.26339 9.33696 5 10 5C10.663 5 11.2989 5.26339 11.7678 5.73223C12.2366 6.20107 12.5 6.83696 12.5 7.5ZM2.5 4.16667V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V4.16667C17.5 3.72464 17.3244 3.30072 17.0118 2.98816C16.6993 2.67559 16.2754 2.5 15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667Z" fill="#73738D"/>
</svg>
`,
"Email":`<i style="color:#73738D" class="fa-solid fa-envelope"></i>`,
"Phone Number":`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.8333 15.8333H4.16667V6.66665H15.8333M13.3333 0.833313V2.49998H6.66667V0.833313H5V2.49998H4.16667C3.24167 2.49998 2.5 3.24165 2.5 4.16665V15.8333C2.5 16.2753 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2753 17.5 15.8333V4.16665C17.5 3.24165 16.75 2.49998 15.8333 2.49998H15V0.833313" fill="#73738D"/>
</svg>

`,
"Date":`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.8333 15.8333H4.16667V6.66665H15.8333M13.3333 0.833313V2.49998H6.66667V0.833313H5V2.49998H4.16667C3.24167 2.49998 2.5 3.24165 2.5 4.16665V15.8333C2.5 16.2753 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2753 17.5 15.8333V4.16665C17.5 3.24165 16.75 2.49998 15.8333 2.49998H15V0.833313" fill="#73738D"/>
</svg>
`,
"Website":`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6333 11.6667C13.7 11.1167 13.75 10.5667 13.75 10C13.75 9.43335 13.7 8.88335 13.6333 8.33335H16.45C16.5833 8.86669 16.6667 9.42502 16.6667 10C16.6667 10.575 16.5833 11.1334 16.45 11.6667M12.1583 16.3C12.6583 15.375 13.0417 14.375 13.3083 13.3334H15.7667C14.9667 14.7084 13.6917 15.775 12.1583 16.3ZM11.95 11.6667H8.04999C7.96666 11.1167 7.91666 10.5667 7.91666 10C7.91666 9.43335 7.96666 8.87502 8.04999 8.33335H11.95C12.025 8.87502 12.0833 9.43335 12.0833 10C12.0833 10.5667 12.025 11.1167 11.95 11.6667ZM9.99999 16.6334C9.30832 15.6334 8.74999 14.525 8.40832 13.3334H11.5917C11.25 14.525 10.6917 15.6334 9.99999 16.6334ZM6.66666 6.66669H4.23332C5.02499 5.28335 6.30832 4.21669 7.83332 3.70002C7.33332 4.62502 6.95832 5.62502 6.66666 6.66669ZM4.23332 13.3334H6.66666C6.95832 14.375 7.33332 15.375 7.83332 16.3C6.30832 15.775 5.02499 14.7084 4.23332 13.3334ZM3.54999 11.6667C3.41666 11.1334 3.33332 10.575 3.33332 10C3.33332 9.42502 3.41666 8.86669 3.54999 8.33335H6.36666C6.29999 8.88335 6.24999 9.43335 6.24999 10C6.24999 10.5667 6.29999 11.1167 6.36666 11.6667M9.99999 3.35835C10.6917 4.35835 11.25 5.47502 11.5917 6.66669H8.40832C8.74999 5.47502 9.30832 4.35835 9.99999 3.35835ZM15.7667 6.66669H13.3083C13.0417 5.62502 12.6583 4.62502 12.1583 3.70002C13.6917 4.22502 14.9667 5.28335 15.7667 6.66669ZM9.99999 1.66669C5.39166 1.66669 1.66666 5.41669 1.66666 10C1.66666 12.2102 2.54463 14.3298 4.10743 15.8926C4.88125 16.6664 5.79991 17.2802 6.81096 17.699C7.82201 18.1178 8.90564 18.3334 9.99999 18.3334C12.2101 18.3334 14.3297 17.4554 15.8925 15.8926C17.4553 14.3298 18.3333 12.2102 18.3333 10C18.3333 8.90567 18.1178 7.82204 17.699 6.81099C17.2802 5.79994 16.6664 4.88129 15.8925 4.10746C15.1187 3.33364 14.2001 2.71981 13.189 2.30102C12.178 1.88224 11.0943 1.66669 9.99999 1.66669Z" fill="#73738D"/>
</svg>
`,
"organisation":`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.58333 0.833313L1.66666 4.99998V6.66665H17.5V4.99998M13.3333 8.33331V14.1666H15.8333V8.33331M1.66666 18.3333H17.5V15.8333H1.66666M8.33333 8.33331V14.1666H10.8333V8.33331M3.33333 8.33331V14.1666H5.83333V8.33331H3.33333Z" fill="#73738D"/>
</svg>
`
      }
    };
  },
  methods: {
    
    addNewInfoType(element) {
      if (element.newInfoType != "") {
        const obj = {};
        obj.text = element.newInfoType;
        this.block.infoType.push(obj);
        this.block.newInfoType = "";
        this.block.addInfoTypeEdit = false;
      } else {
        this.block.addInfoTypeEdit = false;
      }
    },
    removeInfoType(subIndex) {
      this.block.infoType.splice(subIndex, 1);
    },
    infoTypeExist(type) {
      if (type.fieldName == "phone_number") {
        return this.getAllUsedLeadInputs.includes("number");
      } else {
        return this.getAllUsedLeadInputs.includes(type.fieldName);
      }
    },
    toggleEnableOTP(e) {
      this.$emit("toggleEnableOTP", e);
    },
    setInfoType(type) {
      this.block.selectedInfoType = type;
    },
    setDateFormat(value) {
      this.block.selectedDateFormat = value.value;
    },
    setDateSeperator(value) {
      this.block.selectedDateFormatSeperator = value.value;
    },
  },
  mounted() {
    if (localStorage.getItem("QuizID")) {
      this.quizID = localStorage.getItem("QuizID");
    }
  },
  computed:{
    getFontFamilyList() {
return fontFamilyList;
},
DateFont(){
      return this.block.style.DateFontFamily
    },
  },  watch:{
    DateFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    }
  }
};
</script>
<style scoped>
.text-align-section label{
 padding: 6px 12px;
 border: 1px solid transparent;
 margin: 0px;
 cursor:pointer;
 margin:0px;
}
.text-align-section label:hover{
    color: #000;
}
.label-active {
    border-radius: 6px!important;
border: 1px solid #D2D8E0!important;
background: #FFF!important;
color: black!important;
        }
</style>
<style>
.text-align-section{
    color: #B3AFB6;
    background: #F7F7F7;
    width: fit-content;
    border-radius: 6px;

}
.text-align-section label{
 padding: 6px 12px;
 border: 1px solid transparent;
 margin: 0px;
 cursor:pointer;
}
.text-align-section label:hover{
    color: #000;
}
.label-active {
    border-radius: 6px!important;
border: 1px solid #D2D8E0!important;
background: #FFF!important;
color: black!important;
        }
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

</style>
